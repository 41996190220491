.button_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  box-shadow: 0 8px 16px -3px rgba(0, 0, 0, 0.15);
  padding: 1.6rem 0;
  background-color: white;
  height: 6rem;
}

.alphabet {
  background-color: #ffffff;
  margin-top: 14px;
  overflow-y: scroll;
  /* height: 400px; */
  height: calc(100vh - 12rem);
  border-radius: 6px;
  padding: 8px 16px;
}

.alphabet_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
  padding: 1.2rem 0.6rem;
  color: var(--neutral-color-600);
  border-bottom: 1px solid var(--neutral-color-200);
  cursor: pointer;
}
.alphabet_container:hover {
  background-color: #eefff850 !important;
}

.alphabet_title {
  font-size: 1.4rem;
  padding: 0.4rem 0.6rem;
  color: var(--neutral-color-600);
  /* border-bottom: 1px solid var(--neutral-color-200); */
}

.alphabet_open {
  color: var(--primary-color) !important;
  font-size: 1.4rem !important;
  border: 0 !important;
  background-color: #eefff8 !important;
}

.button_container button {
  width: 100%;
  /* padding: 1.2rem; */
  border-radius: 4px;
}

.subject_container__hidden {
  display: none;
}

.subject_container__visible {
  background-color: #eefff8;
  padding: 1.5rem 0;
}

.subject {
  color: var(--primary-color) !important;
  margin-right: 2.6rem;
  border-bottom: 0 !important;
  padding: 0.5rem 0.6rem !important;
  border-right: 1px solid var(--primary-color) !important;
}

.arrow {
  font-size: 1.4rem;
  transform: rotateZ(90deg);
  color: var(--neutral-color-400);
  margin: 0 1.6rem;
}

.arrow__up {
  transform: rotateZ(-90deg);
}

@media screen and (min-width: 600px) {
  .button_container {
    padding: 1.3rem 1.2rem;
  }

  .alphabet_container,
  .alphabet_title {
    font-size: 1.4rem !important;
  }

  .subject {
    font-size: 1.4rem !important;
  }

  .alphabet {
    height: calc(100vh - 22rem);
  }
}
