.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  background-color: var(--neutral-color-600);
  color: #ffffff;
  font-size: 1rem;
  border-radius: 16px;
  padding: 0.5rem 1.6rem;
}

.close {
  font-size: 1.2rem;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .container {
    font-size: 1.2rem;
  }
}
