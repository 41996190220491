.link_container {
  /* display: none; */
  /* font-size: 1.2rem; */
  display: flex;
  gap: 3.2rem;
  font-size: 1.4rem;
}

.link_container span {
  display: inline-block;
}

.font_color {
  color: var(--neutral-color-600);
}

.link_container__in_modal {
  display: flex;
  flex-direction: column;
}

.link_container__in_modal span {
  border-bottom: 1px solid #e8e8e8;
  padding: 10px 16px;
}

@media screen and (min-width: 1024px) {
  .link_container {
    display: flex;
    gap: 3.2rem;
    font-size: 1.4rem;
  }
}
