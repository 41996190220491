.main_container {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #FFFFFF;
    z-index: 2;
}

.container {
    width: 91.1%;
    height: 100%;
    background-color: #FFFFFF;
    box-shadow: -3px 8px 16px -3px #00000026;
    padding: 20px 0;
}

.close {
    margin-left: 8px;
    font-size: 2.4rem;
    color: var(--neutral-color-400);
    text-align: left;
    padding: 0 16px;
}
