.container {
  color: var(--neutral-color-600);
}
.header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  font-size: 1.4rem;
  padding: 0 1.6rem;
}

.content_container {
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
  background-color: white;
  padding: 0.8rem;
}

.content_container p {
  padding: 0.4rem 0rem;
}

.content_container__title {
  display: flex;
  margin: 0.5rem 0rem;
  gap: 0.8rem;
  color: var(--neutral-color-600);
}

.shape_gold {
  width: 100%;
  height: auto;
  padding: 1.6rem;
}

.shape_green {
  width: 70%;
  height: auto;
  padding: 0 1.6rem;
}

.button_container {
  display: flex;
  justify-content: left;
}

.button_container button {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 1rem 2.2rem;
  border-radius: 4px;
  margin: 0 1.6rem 1.6rem;
}

@media screen and (min-width: 600px) {
  .content_container__title {
    font-size: 1.2rem;
    margin: 1rem 0rem;
  }
  .content_container {
    padding: 1.6rem;
  }
}
