@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: IRANSansWeb;
  src: url("./assets/fonts/IRANSansWeb(FaNum).woff");
}

@import url("https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2&family=Gulzar&family=Harmattan&family=Noto+Sans+Arabic&family=Rubik&family=Vazirmatn&display=swap");

:root {
  --primary-color: #0bab64;

  --primary-color-light: #0bab6450;
  --secondary-brown-color: #d69f69;
  --orange: #fcba03;
  --secondary-blue-color: #0085ff;
  --secondary-whaite-color: #eefff8;
  --blue-100: #f7fafc;

  --neutral-color-700: #2f3542;
  --neutral-color-600: #57606f;
  --neutral-color-500: #747d8c;
  --neutral-color-400: #a4b0be;
  --neutral-color-300: #ced6e0;
  --neutral-color-200: #dfe4ea;
  --neutral-color-100: #f1f2f6;
  --error-color: #ff0000;

  --green-100: #0bab6433;
  --green-200: #0bab6485;
  --blue-200: #0085ff33;
  --yellow-100: #d69f6933;
  --red-100: #ff000033;
}

html {
  font-size: 62.5%;
  font-family: IRANSansWeb, serif;
  /* font-family: "Rubik", sans-serif; */
  /* font-family: "Gulzar", serif; */
  /* font-family: "Baloo Bhaijaan 2", sans-serif; */
  /* font-family: "Harmattan", sans-serif; */
  /* font-family: "Noto Sans Arabic", sans-serif; */
  /* font-family: "Vazirmatn", sans-serif; */

  line-height: 2;
  background-color: var(--blue-100);
}

body {
  font-size: 1.6rem;
}

.t-iran-sans {
  font-family: IRANSansWeb;
}

.t-4x-large {
  font-size: 2.6rem;
}

.t-2x-large {
  font-size: 2rem;
}


.t-extra-large {
  font-size: 1.3rem;
}

.t-large {
  font-size: 1.2rem;
}

.t-semi-large {
  font-size: 1.1rem;
}


*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

button {
  border: none;
  outline: none;
}

a {
  text-decoration: none;
  cursor: "pointer";
}

.Toastify__toast {
  font-family: IRANSansWeb, serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: 0.04em;
}

.section-center {
  /* padding: 0 1.8rem; */
}

/* .rct-icons-fa4 .rct-icon-expand-close::before {
  content: "\f053" !important;
} */
.rct-icons-fa4 .rct-icon-parent-close::before {
  content: "\f053" !important;
}

.rct-icons-fa4 .rct-icon-parent-open::before {
  content: "\f078" !important;
}

.react-checkbox-tree ol ol {
  padding-right: 24px !important;
  padding-left: 0 !important;
}

.rct-text {
  align-items: start !important;
  position: relative;
}

.rct-checkbox {
  display: none;
}

.rct-node-icon {
  /* display: none; */
}

.rct-node-expanded {
  background-color: #eefff8 !important;
}

.rct-node-expanded .rct-title {
  color: var(--primary-color) !important;
}

.rct-node-clickable {
  width: 100%;
  /* position: absolute;
  right: 0;
  left: 0;
  padding-right: 25px; */
}

.rct-collapse.rct-collapse-btn {
  height: 34px;
  display: none;
}

.rct-node-icon {
  color: #82828b !important;
}

.b-red {
  background-color: #aaefd5;
}

.rct-node-clickable:focus {
  background: transparent !important;
}

.arabic-text {
  color: blue;
  position: relative;
}

.arabic-text::before,
.arabic-text::after {
  content: attr(data-text);
  color: red;
  position: absolute;
  top: 0;
  right: 0;
}

.arabic-text {
  font-size: 18px;
  color: blue;
  direction: rtl;
  /* Right-to-left text direction for Arabic */
}

.diacritic {
  color: red;
}

.main-bg {
  background-image: url("assets/images/MainBG.png");
}

::-webkit-scrollbar {
  width: 10px;
}


.MuiAutocomplete-option {
  font-family: IRANSansWeb;
  font-size: 16px;
}

.MuiAutocomplete-option:hover {
  background-color: var(--blue-200) !important;

}