.form_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5.6rem;
  width: 90%;
}

.form_container h1 {
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  color: var(--primary-color);
}

.form_container input {
  height: 4rem;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

.form_container input:focus {
  outline: none;
  border: 1px solid var(--primary-color);
}

.form_container input::placeholder {
  color: var(--neutral-color-400);
}

.form_container input:first-child {
  margin-bottom: 16px;
}

.form_container button {
  font-size: 1.4rem;
  font-weight: 500;
  width: 100%;
  height: 4rem;
  padding: 5px 0;
  border-radius: 8px;
}

.form_container span {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--neutral-color-600);
}

.form_container a {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--secondary-blue-color);
}

@media screen and (min-width: 768px) {
  .form_container {
    height: 100%;
    width: 38%;
    background-color: #ffffff;
    padding: 0 24px;
    margin-right: 3%;
  }
}
@media screen and (min-width: 1440px) {
  .form_container {
    background-color: transparent;
    padding: 0;
  }
}
