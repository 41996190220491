.container {
    height: 100vh;
    width: 100%;
}

@media screen and (min-width: 768px)  {
    .container{
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url("../../../../assets/images/login/login_bg.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
