.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 5rem;
  background-color: var(--primary-color);
  color: #ffffff;
  font-size: 1.6rem;
  padding: 0 1.6rem;
  position: sticky;
  top: 0;
  z-index: 99;
  margin-bottom: 1rem;
}

.container > div {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1024px) {
  .container {
    padding: 0 6.8rem;
  }

  .container > div:first-child div {
    margin-right: 13.2rem;
  }
}
