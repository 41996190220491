.container {
  position: relative;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 0 0.8rem;
  height: 40px;
  cursor: pointer;
}

.dropdown_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  color: var(--neutral-color-400);
  font-size: 1.2rem;
}

.arrow {
  font-size: 1.5rem;
  transform: rotateZ(90deg);
}

.arrow_up {
  transform: rotateZ(-90deg);
}

.menu {
  position: absolute;
  left: 0;
  /* top: 0; */
  width: 100%;
  list-style-type: none;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  overflow: scroll;
  z-index: 10000;
  max-height: 192px;
}

.menu>li {
  padding: 0.6rem 1.6rem;
  background-color: white;
  font-size: 1.5rem;
  color: var(--neutral-color-400);
  border: none;
  margin: 0;
  cursor: pointer;
}

.menu>li:hover {
  background-color: var(--neutral-color-100);
}

.filterOption:hover {
  background-color: #0bab6460;
  cursor: pointer;
}