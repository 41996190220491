.btn {
  font-family: inherit;
  cursor: pointer;
  font-size: 1.6rem;
}

.btn:disabled {
  cursor: none;
  background-color: gray;
}

.btn-primary {
  background-color: var(--primary-color);
  color: #fff;
}

.btn-info {
  background-color: var(--yellow-100);
  color: var(--secondary-brown-color);
}


.btn-warn {
  background-color: var(--secondary-blue-color);
  color: #fff;
}


.btn-primary:hover,
.btn-info:hover {
  opacity: 0.75;
}

.btn-secondary {
  background-color: #fff;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.btn-secondary:hover {
  background-color: var(--primary-color);
  color: #fff;
  opacity: 1;
}