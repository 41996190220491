.frame {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 768px) {
    .frame{
        justify-content: flex-start;
        background-color: transparent;
        height: 74.21%;
        width: 93.75%;
        border: 4px solid #FFF;
        box-shadow: 2px 8px 16px 2px rgba(0, 0, 0, 0.15);
    }
}

@media screen and (min-width: 1440px) {
    .frame{
        width: 81.25%;
    }
}
